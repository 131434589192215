
import ChangelogBulletTemplate from "./ChangelogBulletTemplate.vue";
import { Changelog } from "@/services/updateService";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChangelogTemplate",
  components: { ChangelogBulletTemplate },
  props: {
    log: {
      type: Object as PropType<Changelog>,
      required: true,
    },
  },
  computed: {
    changes(): any {
      return this.log?.changes || [];
    },
    fixes(): any {
      return this.log?.fixes || [];
    },
    newChanges(): any {
      return this.log?.new || [];
    },
  },
});
