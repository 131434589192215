
import { PopoutsModule } from "@/store/modules/popouts";
import ChangelogTemplate from "./ChangelogTemplate.vue";
import { Changelog, getChangelog } from "@/services/updateService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { ChangelogTemplate },
  props: {
    identity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logs: null as Changelog[] | null,
    };
  },
  mounted() {
    getChangelog().then((logs) => {
      this.logs = logs;
    });
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout(this.identity);
    },
    backgroundClick(event: any) {
      if (!event.target.classList.contains("popout-background")) return;
      this.close();
    },
    buttonClicked() {
      this.close();
    },
  },
});
