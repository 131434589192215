
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChangelogBullets",
  props: {
    bullets: {
      type: [Array, String] as PropType<string[] | string>,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    list(): any {
      if (typeof this.bullets === "string") return [this.bullets];
      return this.bullets;
    },
  },
});
